import React from 'react';
import styled from '@emotion/styled';
import { SocMediaIcons } from '../common';
import { MOBILE, MOBILE_L } from '../../constants/responsive';

const linkArr = [
  {
    label: 'Web Development',
    link: '/home/webdev',
    id: 1,
  },
  {
    label: 'Search Engine Optimization',
    link: '/home/seo-promotion',
    id: 2,
  },
  {
    label: 'Data Science & Automation',
    link: '/home/marketing-automation',
    id: 3,
  },
  {
    label: 'Paid Advertising',
    link: '/home/ppc-services',
    id: 4,
  },
  {
    label: 'Branding',
    link: '/home/graphic-design',
    id: 5,
  },
  {
    label: 'Social Media Marketing',
    link: '/home/social-media',
    id: 6,
  },
  {
    label: 'Content Production',
    link: '/home/marketing-automation',
    id: 7,
  },

];

const FooterStyled = styled('footer')`
    display: flex;
    flex-direction: column;
    padding: 3rem 5.5rem;
    gap: 3rem;
    align-items: center;
    background:var(--main-color-black);
    margin-top:auto;
    width:-webkit-fill-available;
    @media(max-width: ${MOBILE_L}) {      
      padding: 2.4rem;
    };
    @media(max-width: ${MOBILE}) {
      padding: 1.6rem 1rem;
      gap: 2.4rem;
    }
`;
const NavBarContainer = styled('div')`
    width:100%;
    display: flex;
    flex-flow: row wrap;
    gap: 2.4rem;
    color:var(--main-color-white);
    align-items: flex-start;
    justify-content: center; 
    @media(max-width: ${MOBILE}) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;     
    };
`;
const LinkBtn = styled('button')`
 background: none;
  border: none;
  text-align: inherit;
  padding:0;
  
`;
function Footer({ navigate }) {
  const currentYear = new Date().getFullYear();
  const onLinkClick = (index) => {
    navigate('/', { state: { slideIndex: index } });
  };
  return (
    <FooterStyled id="footer">
      <NavBarContainer>
        {linkArr.map((nav) => <LinkBtn key={nav.id} onClick={() => { onLinkClick(nav.id); }} className="additional-bold white-font-mod">{nav.label}</LinkBtn>)}
        <LinkBtn className="additional-bold white-font-mod" onClick={() => navigate('/contact/')}>Contact us</LinkBtn>
      </NavBarContainer>
      <SocMediaIcons />
      <div>
        <p className="additional white-font-mod">
          Targetiva LLC
          &nbsp;
          {currentYear}
          . All rights reserverd
        </p>
      </div>
    </FooterStyled>
  );
}

export default Footer;
