import { useState, useEffect } from 'react';
const aws_user_files_s3_bucket = "targetiva-blog-images-storage182046-staging"

const useSendForm = (navigate) => {
  const onSubmitForm = async (values, { setSubmitting }) => {
    try {
      const response = await fetch('https://lz7m2uicwf.execute-api.us-east-1.amazonaws.com/default/targetiva-consultation-request', { method: 'POST', body: JSON.stringify(values) });
      if (response.ok) {
        navigate('/submit/');
      } else if (response.status === 400) {
        // console.log(response);
      }
    } catch (e) {
      // console.log(e);
    }
    setSubmitting(false);
  };
  return onSubmitForm;
};
export default useSendForm;

// get real  width and  height and resize fullsize page (slider)

const isBrowser = typeof window !== "undefined";
function getWindowDimensions() {
if (!isBrowser) {
    return {
      width : '100vw',
      height: '100vh',
    };
};
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
function useWindowDimensions() {
 
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    };
   
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};



export {useWindowDimensions, isBrowser}



// eslint-disable-next-line import/prefer-default-export
export const getImageUrl = (objectName) => objectName && encodeURI(`https://${aws_user_files_s3_bucket}.s3.amazonaws.com/public/${objectName}`);

