import React from 'react';
import { Link } from "gatsby";
import styled from '@emotion/styled';
import AppLayout from '../components/AppLayout';
import Footer from '../components/Footer';
import { MOBILE } from '../constants/responsive';
import { getImageUrl } from '../utils';

const BlogPost = ({
  pageContext : {
    title,
    content,
    publishAt,
    featuredImage,
    categories,
    author
  },
  location,
  navigate
}) => {
   const postDate = new Date(publishAt).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
   const url = getImageUrl(featuredImage);
   const categoryMapper = {
    'WEBDEV': 'Web Development',
    'SEOPROMOTION': 'Search Engine Optimization',
    'MARKETINGAUTOMATION': 'Data Science & Automation',
    'PPCSERVICES': 'Paid Advertising',
    'GRAPHICDESIGN': 'Branding',
    'SOCIALMEDIA': 'Social Media Marketing',
    'CONTENTPRODUCTION': 'Content Production'
   };
   console.log(categoryMapper)
   console.log(categories)
  
  return (
    <AppLayout location={location} navigate={navigate}>
      <PageContainer>
        <ContentContainer>
          <h1 className='h1 page-width'>{title}</h1>
          <FeaturedImage src={url} alt={featuredImage} />
          <TextContainer className='page-width'>
            <InfoBlock>
              {author&&<div>Author:&nbsp;{author}</div> }
              {postDate&&<div>{postDate}</div>} 
              <div>{categoryMapper[categories]}</div>         
            </InfoBlock>       
            <Content dangerouslySetInnerHTML={{ __html: content }} />  
            <Link to='/blog'><p><span>&larr;&nbsp;</span>Back to blog</p></Link>           
          </TextContainer>            
        </ContentContainer>
        <Footer navigate={navigate} />
      </PageContainer>
    </AppLayout>
   
  )
}

export default BlogPost;



const PageContainer = styled('div')`
    margin: 0px auto;
    min-height: 100vh; 
    width:100%;
    background:var(--main-color-white);
    
 `; 
 const ContentContainer = styled('div')`
    padding-top: 15.3rem;
    padding-bottom:15rem;
    display:flex;
    flex-direction:column;
    justify-content: center;
    text-align:center;
    align-items: center;
    row-gap: 3rem;
    @media (max-width:${MOBILE}) {
      padding-top: 9rem;
      padding-bottom:5rem;
      row-gap: 0.8rem;
    }
`;
const TextContainer = styled('main')`
  display: flex;
  flex-direction:column;
  max-width: var(--page-width);
  width: 100%;
  margin: 0 auto;  
  @media (max-width:${MOBILE}) {
     padding-top: 1rem;
     padding-bottom:5rem;    
  }
`;
const FeaturedImage = styled('img')`
    width: 100%;
    height: 100%;
    align-self: center;
    margin: 4rem 0 5.8rem;
    max-height: 60rem;
    object-fit: cover;
    @media (max-width:${MOBILE}) {
        margin: 2rem 0;
         }
`;
const Content = styled('article')`
  text-align: left;
  font-family: var(--ff-product-sans), sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 140%;
  color: var(--main-color-black);
  margin: 0;
  padding-top:3rem;
  padding-bottom:5rem;
  p {
    margin-bottom: 1rem;
  };
  img {
    float: left;
  }
  @media (max-width:${MOBILE}) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding-top:1rem;
    padding-bottom:1.5rem;
  }
`;
const InfoBlock = styled('div')`
  display: flex;
  gap: 3rem;
  font-size:12px;
  & div:last-child {
    margin-left: auto;
  }
`;

export const Head = ({
  pageContext: {
    seoTitle,
    seoDescription,
    title
  }
}) => {
  return (
    <>
      <title>{ seoTitle || title }</title>
      <meta name="description" content={seoDescription} />
    </>
  )
};